import React from "react";
import '../styles/long1.css';
import '../styles/gogogo.css';
import SEO from "../components/SEO";

const IndexPage = () => (
    <head>
    <meta http-equiv="refresh" content="1; url='/gogogo-ended'" />
  </head>

//     <div class="grid-container">
//     <div className="header">
//         <div className="header1"> 
//             <div className="logo-div go-logo-div">
//                 <a aria-current="page" className="logo" href="/"><div className="driverprofile-logo"></div></a>
//             </div>
//         </div>
//         <a href="https://www.texpresslanes.com/" target="_blank"><div className="header2">What are TEXpress Lanes</div></a>
//         <a href="https://www.texpresslanes.com/maps/texpress-lanes-map" target="_blank"><div className="header3">Where are TEXpress Lanes</div></a>
//         <a href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use" target="_blank"><div className="header4">How to Use TEXpress Lanes</div></a>
//    </div>
//     <div class="left_space"></div>
//     <div className="short1-main">
//         <div className="gogogo-bg">
//         <div className="santa-img"></div>
//           <div className="go-headSection">
//                 <div className="go-leftSection">
//                     <h2 className="gopage-header">Commutes Shouldn’t<br />Take a Toll on You.</h2>
//                     <p className="gopage-header">Enter the TEXpress Lanes Holiday Sweepstakes for a chance to win one year of FREE tolls on the LBJ, NTE &amp; NTE 35W TEXpress Lanes!</p>
//                     <p className="gopage-subtext">You want to get moving and be where you need to be. We ensure that happens by providing a safe, speedy, and reliable commute.<br /><br />And now, we’re giving five lucky drivers a full year of free tolls — you don’t want to miss this!</p>
//                 </div>
//                 <div className="go-rightSection">
//                     {/* <div id='lightbox-inline-form-c984bec0-6b2b-4c0e-b359-8701c976991e' className="gopage-form"></div> */}
//                     <div id='lightbox-inline-form-6fa358ec-0aa4-4983-8b0c-25ac62bd84f0' className="gopage-form"></div>
//                 </div>
//             </div>
//         </div>
//         <div className="gogogo-main2">
//             <div className="go-section2">
//                 <div className="go-section2-left">
//                     <div className="redline-img"></div>
//                     <p className="go-section2-left-text">What Are The LBJ/NTE/NTE 35W TEXpress Lanes?</p>
//                 </div>
//                 <div className="go-section2-right">
//                     <p className="go-section2-right-text">The LBJ/NTE/NTE 35W TEXpress Lanes are unique toll lanes built within existing highways to accommodate more traffic volume. Unlike traditional toll roads, the price changes based on real-time traffic conditions in order to reduce congestion and keep everyone moving at least 50 MPH.</p>
//                 </div>
//             </div>
//             <div className="go-section2">
//                 <div className="go-section2-left">
//                     <div className="redline-img"></div>
//                     <p className="go-section2-left-text">How Can I Use the LBJ/NTE/NTE 35W TEXpress Lanes?</p>
//                 </div>
//                 <div className="go-section2-right">
//                     <p className="go-section2-right-text">Got a TollTag, TxTag or EZ TAG? You're ready to use the LBJ/NTE/NTE 35W TEXpress Lanes and enter the sweepstakes promotion!</p>
//                 </div>
//             </div>
//         </div>
//         {/* <div className="gogogo-main3">
//             <div className="register-box go-register-box">
//                 <p className="register-box-text">Need help finding your toll transponder number?</p>
//                     <p className="register-box-text">Here are two ways you can locate your toll transponder number:</p>
//                     <div className="register-bot-img"></div>
//                 <p className="register-box-text">Refer to the images above to find the information printed on your toll transponder </p>
//                 <p className="register-box-text">on your vehicle's windshield. The toll transponder number is printed on the side facing the driver in the vehicle.</p>
//                 <p className="register-box-text">You can also find your toll transponder number by logging into your respective toll transponder account. </p>
//                 <div className="register-box-link"><a href="https://www.txtag.org/txtagstorefront/en/" target="_blank">Visit My TxTag Account</a>   <span className="mobile-line">|</span>    <a href="https://www.hctra.org/Login" target="_blank">Visit My EZ TAG Account</a>    <span className="mobile-line">|</span>    <a href="https://csc.ntta.org/olcsc/" target="_blank">Visit My TollTag Account</a> </div>
//             </div>
//         </div> */}
//         <div className="gogogo-main4">
//             <div className="go-main4-padding">
//                 <p className="gogogo-main4-header">Where Can I Use The LBJ/NTE/NTE <br/>35W TEXpress Lanes?</p>
//                 <p className="gogogo-main4-small-text">Sweepstakes promotion valid only on our lanes highlighted blue on the map below:</p>
//                 <p className="gogogo-main4-small-text">LBJ Express (I-635) &amp; North Tarrant Express (I-820, SH 183 and SH 121I-35W)</p>
//                 <div className="map-img"></div>
//                 <a className="go-linkStyle" href="#"><p className="go-button">Click here to fill out the form at the top of page</p></a>
//             </div>
//         </div>
//     </div>
//     <div class="right_space"></div>
//     <div class="footer">
//             <div class="register-footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img class="fb-icon"
//   src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img class="twitter-icon"
//   src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2021 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
//         </div>
// </div>
);

export default IndexPage;
